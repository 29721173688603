import axios from 'axios';
import { toast } from 'react-toastify';
import i18n from 'utils/i18next';

axios.interceptors.request.use(function (config) {
  if (config.pure) {
    return { ...config };
  }
  const token = localStorage.getItem('token');
  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: 'Bearer ' + token,
      },
    };
  }
  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      toast.info(i18n.t('info.common.session_expired', { ns: 'toast' }));
      setTimeout(() => {
        if (window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      }, 2000);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
};
